import Dashboard from '../container/dashboard/dashboard'
import ForgotPassword from '../container/forgot-password'
import Login from '../container/login'
import ManageResident from '../container/manage-residents'
import ResetPassword from '../container/reset-password'
import Company from '../container/manage-company/manageCompany'
import VerifyEmail from '../container/verify-email'
import CompanyList from '../container/company-list/companyList'
import CompanyUser from '../container/manage-company-user/manageCompanyUser'
import CompanyUserList from '../container/company-user-list/companyUserList'
import RelationsList from '../container/relations'
import TimingsList from '../container/timings'
import StaffList from '../container/staff-list'
import ManageStaff from '../container/manage-staff'
import StatesList from '../container/states'
import CityList from '../container/cities'
import FacilityList from '../container/facility'
import ResidentList from '../container/resident-list'
import ViewResident from '../container/view-resident'
import AncmentList from '../container/ancment-list'
import ManageAncment from '../container/manage-ancment'
import Profile from '../container/profile'
import IncidentTypeList from '../container/incident-types'
import ContactTypeList from '../container/contact-type-list'
import ManageFacility from '../container/manage-facility'
import ManageState from '../container/manage-state'
import ManageCity from '../container/manage-city'
import ManageContactType from '../container/manage-contact-type'
import ManageRelation from '../container/manage-relation'
import residentWithoutCompanyLists from '../container/resident-without-company'
import ManageAdminMedications from '../container/manage-admin-medication'


export const allRoutes = [
   {
      path: '/login',
      component: Login,
      isPrivate: false
   },
   {
      path: '/forgot-password',
      component: ForgotPassword,
      isPrivate: false
   },
   {
      path: '/reset-password',
      component: ResetPassword,
      isPrivate: false
   },
   {
      path: '/verify-email',
      component: VerifyEmail,
      isPrivate: false
   },
   {
      path: '/dashboard',
      component: Dashboard,
      isPrivate: true
   },
   {
      path: '/manage-company/:id?',
      component: Company,
      isPrivate: true,
      isExact: false
   },
   {
      path: '/company-list',
      component: CompanyList,
      isPrivate: true
   },
   {
      path: '/resident-list-without-company',
      component: residentWithoutCompanyLists,
      isPrivate: true
   },
   {
      path: '/manage-company-user/:id?',
      component: CompanyUser,
      isPrivate: true
   },
   {
      path: '/company-owner-list',
      component: CompanyUserList,
      isPrivate: true
   },
   {
      path: '/view-resident/:id?',
      component: ViewResident,
      isPrivate: true
   },
   {
      path: '/relation-list',
      component: RelationsList,
      isPrivate: true
   },
   {
      path: '/manage-relation/:id?',
      component: ManageRelation,
      isPrivate: true
   },
   {
      path: '/timings',
      component: TimingsList,
      isPrivate: true
   },
   {
      path: '/staff-list',
      component: StaffList,
      isPrivate: true
   },
   {
      path: '/manage-staff/:id?',
      component: ManageStaff,
      isPrivate: true
   },
   {
      path: '/state-list',
      component: StatesList,
      isPrivate: true
   },
   {
      path: '/manage-state/:id?',
      component: ManageState,
      isPrivate: true
   },
   {
      path: '/city-list',
      component: CityList,
      isPrivate: true
   },
   {
      path: '/manage-city/:id?',
      component: ManageCity,
      isPrivate: true
   },
   {
      path: '/facility-list',
      component: FacilityList,
      isPrivate: true
   },
   {
      path: '/resident-list',
      component: ResidentList,
      isPrivate: true
   },
   {
      path: '/announcements',
      component: AncmentList,
      isPrivate: true
   },
   {
      path: '/manage-announcement/:id?',
      component: ManageAncment,
      isPrivate: true
   },
   {
      path: '/profile',
      component: Profile,
      isPrivate: true
   },
   {
      path: '/incident-types',
      component: IncidentTypeList,
      isPrivate: true
   },
   {
      path: '/contact-type-list',
      component: ContactTypeList,
      isPrivate: true
   },
   {
      path: '/manage-contact/:id?',
      component: ManageContactType,
      isPrivate: true
   },
   {
      path: '/view-admin-medication/:id?',
      component: ManageAdminMedications,
      isPrivate: true
   },
   {
      path: '/create-facility',
      component: ManageFacility,
      isPrivate: true,
   },
   {
      path: '/manage-facility/:id',
      component: ManageFacility,
      isPrivate: true
   },
]